import React from "react";
import {
    DefaultButton,
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
    IDropdownStyles,
    mergeStyleSets,
    Panel,
    Toggle,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Report } from "powerbi-client";
import { applyBookmark, PowerBIPaneState, removeFilter, ReportSettings, setFilter, setSetting } from "./PowerBI";

interface PowerBIOptionPaneProps {
    report: Report;
    settings: ReportSettings;
}

const PowerBIOptionPane: React.FunctionComponent<PowerBIOptionPaneProps> = (props: PowerBIOptionPaneProps) => {
    const { report, settings } = props;
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const optionPaneStyle = mergeStyleSets({
        panelOptionRoot: {
            display: `absolute`,
            position: `absolute`,
            right: `0px`,
            margin: `10px 5px`,
        },
    });

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };

    const states: IDropdownOption[] = [
        { key: "Germany", text: "Germany", itemType: DropdownMenuItemType.Header },
        { key: "Germany-All", text: "All" },
        { key: "Germany-Bayern", text: "Bayern" },
        { key: "Germany-Hamburg", text: "Hamburg" },
        { key: "Germany-Saarland", text: "Saarland" },
        { key: "US", text: "United States", itemType: DropdownMenuItemType.Header },
        { key: "US-All", text: "All" },
        { key: "US-Arizona", text: "Arizona" },
        { key: "US-California", text: "California" },
        { key: "US-Oregon", text: "Oregon" },
    ];

    return (
        <div>
            <DefaultButton text="Open panel" onClick={openPanel} className={optionPaneStyle.panelOptionRoot} />
            <Panel
                isOpen={isOpen}
                onDismiss={dismissPanel}
                isHiddenOnDismiss={true}
                headerText="Settings"
                closeButtonAriaLabel="Close"
                isLightDismiss={true}
            >
                <Toggle
                    label="Show page navigation"
                    defaultChecked
                    onText="On"
                    offText="Off"
                    onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) =>
                        setSetting(report, settings, "pageNavigation", checked as boolean)
                    }
                />
                <Toggle
                    label="Show filter pane"
                    onText="On"
                    offText="Off"
                    onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) =>
                        setSetting(
                            report,
                            settings,
                            "filters",
                            (checked as boolean) ? PowerBIPaneState.expanded : PowerBIPaneState.hidden
                        )
                    }
                />
                <Dropdown
                    label="Apply a state filer"
                    placeholder="Select an state"
                    defaultSelectedKey="All"
                    options={states}
                    styles={dropdownStyles}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                        const keys = (option?.key as string).split("-");
                        switch (keys[1]) {
                            case "All":
                                applyBookmark(report, keys[0]);
                                removeFilter(report);
                                break;
                            default:
                                applyBookmark(report, keys[0]);
                                setFilter(report, "Reseller", "State-Province", "In", [keys[1]]);
                                break;
                        }
                    }}
                />
            </Panel>
        </div>
    );
};

export default PowerBIOptionPane;
