import React, { useState } from "react";
import { mergeStyleSets } from "@fluentui/react";
import { Report } from "powerbi-client";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Frame from "../components/Frame";
import EmbedReport from "../components/powerbi/EmbedReport";
import { PowerBIPaneState } from "../components/powerbi/PowerBI";
import PowerBIOptionPane from "../components/powerbi/PowerBIOptions";

const EmbeddedInteractionPage: React.FunctionComponent<Record<string, unknown>> = () => {
    const [report, setReport] = useState<Report | null>(null);
    const settings = {
        filters: PowerBIPaneState.hidden,
    };

    const headerStyle = mergeStyleSets({
        embedInteractiveRoot: {
            flex: `0 1 auto`,
        },
    });

    return (
        <Layout>
            <div className={headerStyle.embedInteractiveRoot}>
                {report && <PowerBIOptionPane report={report} settings={settings} />}
                <SEO title="Power BI Embedded Analytics | Example" />
                <h1>Power BI Embedded Analytics | Example</h1>
            </div>
            <Frame>
                <EmbedReport
                    reportId="a11829f1-0b66-48f3-b1b5-7fdcdf8f5969"
                    settings={settings}
                    getEmbeddedComponent={(report) => setReport(report as Report)}
                />
            </Frame>
        </Layout>
    );
};

export default EmbeddedInteractionPage;
